@import '../../../styles/var';

.keyFeatures {
    position: relative;
    padding-top: 200px;

    @media(max-width: $md3+px) {
        padding-top: 125px;
    }

    @media(max-width: $md6+px) {
        padding-top: 100px;
    }

    .head {
        display: grid;
        grid-template-columns: auto auto 1fr;
        gap: 140px;
        align-items: center;

        @media(max-width: $md3+px) {
            gap: 100px;
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: unset;
        }

        .title {

            .lines {
                margin-top: 20px;
                width: 95px;

                @media(max-width: $md4+px) {
                    margin-top: 15px;
                }

                @media(max-width: $md6+px) {
                    width: 65px;
                }
            }
        }

        .line {
            height: 100%;
            width: 1px;
            background: rgba(255, 255, 255, 0.50);

            @media(max-width: $md4+px) {
                display: none;
            }
        }

        .text {
            @media(max-width: $md4+px) {
                max-width: 552px;
            }
        }
    }

    .swiperWrap {
        margin-top: 60px;

        @media(max-width: $md4+px) {
            margin-top: 25px;
        }

        .nav {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;
            margin-bottom: 20px;

            @media(max-width: $md6+px) {
                margin-bottom: 10px;
                gap: 13px;
            }

            &Btn {
                width: 40px;
                height: 40px;
                background-image: url('../../../assets/img/swiper_arrow.svg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                cursor: pointer;
                border-radius: 100%;
                transition: all .1s;

                @media(max-width: $md6+px) {
                    width: 30px;
                    height: 30px;
                }

                &:hover {
                    opacity: 0.7;
                }
            }

            .right {
                transform: rotate(180deg);
            }
        }

        .card {
            width: 100%;
            height: 100%;
            padding: 35px 30px;

            @media(max-width: $md3+px) {
                padding: 30px 25px;
            }

            &.dark {
                background: #1F2732;

                .cardTitle {
                    color: $gold;
                }

                .cardText {
                    color: #fff;
                }
            }

            &.light {
                background: $gold;

                .cardTitle {
                    color: #161D26;
                }

                .cardText {
                    color: #161D26;
                }
            }

            &Title {
                font-weight: 600;
            }

            &Text {
                margin-top: 25px;

                @media(max-width: $md6+px) {
                    margin-top: 15px;
                }
            }
        }
    }
}

:global(.aboutBannerSwiper) {
    overflow: visible !important;
}

:global(.aboutBannerSwiperSlide) {
    height: auto !important;
}