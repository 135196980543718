@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    backdrop-filter: blur(10px);
    transition: all 0.1s;
    border-bottom: 1px solid rgba(255, 255, 255, 0.50);

    &.hideBg {
        background-color: rgba($color: #fff, $alpha: 0);
    }

    @media (max-width: $md3+px) {
        height: 71px;
    }

    @media (max-width: $md6+px) {
        height: 65px;
    }

    &::before {
        content: "";
        background: #161D26;
        position: absolute;
        width: 100%;
        height: 0px;
        opacity: 0;
        z-index: -1;
    }

    &Active {
        height: 100%;

        &::before {
            opacity: 1;
            height: 100%;
        }

        .langs {
            display: none;
        }
    }

    .mobileMenu {
        @media (min-width: 1221px) {
            display: none;
        }

        opacity: 0;
        padding-top: 40px;
        pointer-events: none;
        transform: translateY(-400px);
        height: 0;

        .aLink {
            position: relative;
            z-index: 10;
            display: block;
            text-align: center;
            color: #fff;
            font-family: $sans;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px;

            &.linkActive {
                color: #D1B06B;
            }
        }

        .btn {
            position: relative;
            z-index: 10;
            justify-content: center;
            display: flex;
            margin-top: 40px;
        }

        &Active {
            display: flex;
            transition: transform 0.5s;
            transform: translateY(0px);
            opacity: 1;
            pointer-events: visible;
        }
    }

    .headerRow {
        display: grid;
        grid-template-columns: 100px 1fr auto;
        gap: 60px;
        align-items: center;
        padding: 10px 0;

        @media (max-width: $md3+px) {
            display: flex;
            justify-content: space-between;
        }

        &Logo {
            @media (max-width: $md3+px) {
                width: 90px;
            }

            @media(max-width: $md6+px) {
                width: 80px;
            }

            a {
                width: 100%;
                height: 100%;
                display: block;
            }

            img {
                width: 100%;
            }
        }

        &Links {
            @media (max-width: $md3+px) {
                display: none;
            }

            display: flex;
            gap: 40px;
            font-family: $sans;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;

            .linkActive {
                color: #D1B06B;
            }

            a {
                position: relative;
                color: #fff;
                text-decoration: none;
            }
        }

        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 45px;

            .login {
                @media (max-width: $md3+px) {
                    display: none;
                }

                padding: 10px 20px;
                background: #D1B06B;
                border: 1px solid #D1B06B;
                font-family: $sans;
                font-weight: 600;
                font-size: 16px;
                line-height: 120%;
                color: #000;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    color: #D1B06B;
                    background: transparent;
                }
            }

            .burger {
                @media (min-width: 1221px) {
                    display: none;
                }

                cursor: pointer;
                display: flex;
                width: 32px;
                height: 14px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #D1B06B;
                    border-radius: 10px;
                    transition: all 0.3s;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #D1B06B;
                    border-radius: 10px;
                    transition: all 0.3s;
                }

                &Active {
                    &::before {
                        top: 6px;
                        transform: rotate(45deg);
                    }

                    &::after {
                        bottom: 6px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

:global(.Dropdown-control) {
    font-family: $sans !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
    text-transform: uppercase !important;
    padding-right: 20px !important;
    padding-left: 0;
    cursor: pointer !important;

    &:hover {
        box-shadow: none;
    }
}

:global(.Dropdown-menu) {
    font-family: $sans !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

:global(.Dropdown-arrow) {
    border-color: #fff transparent transparent !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    right: 0!important;
}

:global(.is-open .Dropdown-arrow) {
    border-color: transparent transparent #fff !important;
}

:global(.Dropdown-option) {
    padding: 6px 11px!important;
}