@import "./_var.scss";
@import './mixins';

body,
html {
    background: #161D26;
    color: #fff;

    &.lock {
        overflow: hidden;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: #161D26;
    }

    ::-webkit-scrollbar-thumb {
        background: $gold;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $gold;
    }
}

.font-80 {
    color: $gold;
    font-family: $sans;
    font-size: 80px;
    font-weight: 800;
    line-height: 130%;

    @media(max-width: $md6+px) {
        font-size: 60px;
    }
}

.font-60 {
    color: #FFF;
    font-family: $sans;
    font-size: 60px;
    font-weight: 400;
    line-height: 140%;

    @media(max-width: $md4+px) {
        font-size: 45px;
    }

    @media(max-width: $md6+px) {
        font-size: 30px;
    }

    span {
        color: $gold;
    }
}

.font-40 {
    color: #FFF;
    font-family: $sans;
    font-size: 40px;
    font-weight: 400;
    line-height: 140%;

    @media(max-width: $md4+px) {
        font-size: 35px;
    }

    @media(max-width: $md6+px) {
        font-size: 28px;
    }
}

.font-30 {
    color: #FFF;
    font-family: $sans;
    font-size: 30px;
    font-weight: 400;
    line-height: 140%;

    @media(max-width: $md6+px) {
        font-size: 28px;
    }
}

.font-22 {
    color: $gold;
    font-family: $sans;
    font-size: 22px;
    font-weight: 400;
    line-height: 140%;

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-20 {
    color: $gold;
    font-family: $sans;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-16 {
    color: #fff;
    font-family: $sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md6+px) {
        font-size: 14px;
    }

    b {
        color: $gold;
        font-weight: 600;
    }
}

.mt {
    margin-top: 200px;

    @media (max-width: $md4+px) {
        margin-top: 160px;
    }

    @media (max-width: $md6+px) {
        margin-top: 100px;
    }
}

.mt-btn {
    margin-top: 50px;

    @media(max-width: $md4+px) {
        margin-top: 40px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.ovf-padding {
    padding-right: 5px;

    @media(max-width: $md3+px) {
        padding: 0;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
