@import '../../../styles/var';

.various {
    position: relative;

    .gtitleWrap {
        display: flex;
        justify-content: center;
        margin-bottom: 110px;

        @media(max-width: $md4+px) {
            margin-bottom: 60px;
        }

        @media(max-width: $md6+px) {
            margin-bottom: 40px;
        }

        .gtitle {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            text-align: center;
            margin: 0 auto;

            .lines {
                position: absolute;
                top: calc(50% - 22px);
                height: 44px;

                @media(max-width: $md6+px) {
                    height: 25px;
                    top: 7px;
                }

                &Left {
                    left: -67px;
                    transform: translateX(-100%);

                    @media(max-width: $md6+px) {
                        left: 60px;
                    }
                }

                &Right {
                    right: -67px;
                    transform: translateX(100%) rotateZ(180deg);

                    @media(max-width: $md6+px) {
                        right: 60px;
                    }
                }
            }
        }
    }

    .block {
        display: grid;
        grid-template-columns: repeat(2, 525px);
        gap: 60px;
        justify-content: space-between;

        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: 1100px) {
            grid-template-columns: 1fr 450px;
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 25px;
            justify-content: initial;
        }

        @media(max-width: $md6+px) {
            gap: 20px;
        }

        .content {
            width: 100%;
            .title {}

            .linesSmall {
                width: 95px;
                margin: 20px 0 30px 0;

                @media(max-width: $md4+px) {
                    margin: 15px 0;
                }

                @media(max-width: $md6+px) {
                    width: 65px;
                }
            }

            .text {}
        }

        .col {
            width: 100%;

            .swiperWrap {
                width: 100%;
                max-width: 525px;

                @media(max-width: $md4+px) {
                    margin: 0 auto;
                }

                .nav {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 20px;
                    margin-bottom: 30px;

                    @media(max-width: $md4+px) {
                        margin-bottom: 20px;
                    }

                    @media(max-width: $md6+px) {
                        margin-bottom: 10px;
                        gap: 13px;
                    }

                    &Btn {
                        width: 40px;
                        height: 40px;
                        background-image: url('../../../assets/img/swiper_arrow.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: cover;
                        cursor: pointer;
                        transition: all .1s;

                        @media(max-width: $md6+px) {
                            width: 30px;
                            height: 30px;
                        }

                        &:hover {
                            opacity: 0.7;
                        }
                    }

                    .right {
                        transform: rotate(180deg);
                    }
                }

                .card {
                    width: 100%;
                    height: 100%;
                    background: #1F2732;

                    &Image {
                        width: 100%;
                        height: 164px;
                        object-fit: cover;
                        object-position: center;
                        
                        @media(max-width: $md6+px) {
                            height: 115px;
                        }
                    }

                    &Text {
                        padding: 20px;

                        @media(max-width: $md6+px) {
                            padding: 20px 15px;
                        }
                    }
                }
            }
        }
    }
}

:global(.variousSwiper) {
    width: 100%;
    @media(max-width: $md4+px) {
        overflow: visible !important;
    }
}

:global(.variousSwiperSlide) {
    height: auto !important;
}