@import '../../../styles/var';

.wrap {
    display: flex;
    align-items: center;
    height: 100vh;
    padding-top: 66px;
    min-height: 700px;

    @media(max-width: $md2+px) {
        min-height: 600px;
    }

    @media(max-width: $md3+px) {
        padding-top: 125px;
        justify-content: center;
        align-items: unset;
        height: auto;
        min-height: auto;
        flex-direction: column;
    }

    @media(max-width: $md6+px) {
        padding-top: 100px;
    }

    @media(max-width: $md6+px) {
        padding-top: 100px;
    }
}

.container {
    width: 100%;
}

.banner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;

    @media(max-width: $md3+px) {
        justify-content: center;
        align-items: unset;
        flex-direction: column;
        gap: 0;
    }

    .content {
        @media(max-width: $md3+px) {
            text-align: center;
        }

        .title {}

        .lines {
            width: 95px;
            margin: 20px 0 30px 0;

            @media(max-width: $md3+px) {
                display: none;
            }
        }

        .text {
            max-width: 525px;
            
            @media(max-width: $md3+px) {
                margin: 30px auto 0;
            }
        }
    }

    .image {
        width: 514px;

        @media(max-width: $md3+px) {
            position: relative;
            top: unset;
            transform: none;
            display: flex;
            margin: 40px auto 0;
        }

        @media(max-width: $md4+px) {
            width: 465px;
        }

        @media(max-width: 600px) {
            width: 400px;
        }

        @media(max-width: $md6+px) {
            width: 273px;
            margin-top: 30px;
        }
    }
}