@import '../../../styles/var';

.wrap {
    display: flex;
    align-items: center;
    height: 100vh;
    padding-top: 66px;
    min-height: 700px;

    @media(max-width: $md2+px) {
        min-height: 600px;
    }
    
    @media(max-width: $md3+px) {
        padding-top: 125px;
        justify-content: center;
        align-items: unset;
        height: auto;
        min-height: auto;
        flex-direction: column;
    }

    @media(max-width: $md6+px) {
        padding-top: 100px;
    }

    @media(max-width: $md6+px) {
        padding-top: 100px;
    }

    .image {
        width: 713px;
        @media(max-width: $md2+px) {
            width: 600px;
        }

        @media(max-width: $md3+px) {
            position: relative;
            top: unset;
            transform: none;
            margin-left: auto;
            display: flex;
            margin-top: 60px;
        }

        @media(max-width: $md4+px) {
            width: 80%;
        }

        @media(max-width: $md6+px) {
            margin-top: 50px;
        }
    }
}

.container {
}

.banner {
    width: 100%;

    .content {
        @media(max-width: $md3+px) {
            text-align: center;
        }
        .title {

        }
        .text {
            margin-top: 30px;
            max-width: 525px;
        }
    }
}