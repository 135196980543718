@import '../../../styles/var';

.wrap {
    max-width: 2000px;
    width: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    @media(max-width: $md3+px) {
        margin-top: 160px;
    }

    @media(max-width: $md6+px) {
        margin-top: 100px;
    }
}

.panel {
    position: relative;

    .images {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @media(max-width: $md4+px) {
            position: relative;

            display: none;
        }

        .image {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            object-fit: cover;
            object-position: top right;
            opacity: 0;
            transition: all .3s;

            &.active {
                z-index: 3;
                opacity: 1;
            }
        }
    }

    .contentWrap {
        position: relative;
        z-index: 4;
        height: 430px;
        display: flex;
        align-items: center;
        width: 720px;
        padding-right: 150px;

        @media(max-width: $md3+px) {
            padding-right: 50px;
            height: 380px;
        }

        @media(max-width: $md4+px) {
            padding: 0;
            height: auto;
            width: 100%;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: 3;
            background: rgba(55, 57, 63, 0.91);
            backdrop-filter: blur(9.5px);
            width: 100vw;
            height: 100%;
            top: 0;
            right: 0;

            @media(max-width: $md4+px) {
                display: none;
            }
        }

        .swiperWrap {
            position: relative;
            z-index: 5;
            width: 100%;

            .card {
                height: 100%;

                @media(max-width: $md4+px) {
                    background: #1F2732;
                }

                &Image {
                    display: none;

                    @media(max-width: $md4+px) {
                        display: block;
                        width: 100%;
                        height: 164px;
                        object-fit: cover;
                        object-position: top right;
                    }

                    @media(max-width: $md6+px) {
                        height: 115px;
                    }
                }

                &Content {
                    @media(max-width: $md4+px) {
                        padding: 40px 30px;
                    }

                    @media(max-width: $md6+px) {
                        padding: 30px 15px;
                    }
                }

                .title {}

                .lines {
                    width: 95px;
                    margin: 20px 0 30px 0;

                    @media(max-width: $md4+px) {
                        margin: 15px 0;
                    }

                    @media(max-width: $md6+px) {
                        width: 65px;
                    }
                }

                .text {}
            }

            .nav {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-top: 30px;

                @media(max-width: $md4+px) {
                    margin-top: 0;
                    margin-bottom: 20px;
                    justify-content: flex-end;
                }

                @media(max-width: $md6+px) {
                    margin-bottom: 10px;
                    gap: 13px;
                }

                &Btn {
                    width: 40px;
                    height: 40px;
                    background-image: url('../../../assets/img/swiper_arrow.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    cursor: pointer;
                    transition: all .1s;

                    @media(max-width: $md6+px) {
                        width: 30px;
                        height: 30px;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }

                .right {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

:global(.toolsUndbSwiper) {
    width: 100%;

    @media(max-width: $md4+px) {
        overflow: visible !important;
    }
}

:global(.toolsUndbSwiperSlide) {
    height: auto !important;
}