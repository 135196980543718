@import '../../../styles/var';

.tabs {
    @media(max-width: $md3+px) {
        margin-top: 160px;
    }

    @media(max-width: $md6+px) {
        margin-top: 100px;
    }

    .buttons {
        display: flex;
        align-items: center;
        gap: 12px;

        @media(max-width: $md3+px) {
            justify-content: space-between;
            flex-wrap: wrap;
        }

        @media(max-width: $md4+px) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        @media(max-width: $md5+px) {
            grid-template-columns: repeat(2, 1fr);

            .btn:nth-child(5) {
                transform: translateX(calc(50% + 6px));
            }
        }

        .btn {
            color: #fff;
            font-family: $sans;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            background: #1F2732;
            transition: all .1s;
            cursor: pointer;
            padding: 15px 45px;
            text-align: center;

            @media(max-width: $md4+px) {
                font-size: 16px;
                padding: 12px 25px;
            }

            @media(max-width: $md6+px) {
                font-size: 14px;
                padding: 10px 20px;
            }

            &.active {
                color: #000;
                background: $gold;
            }
        }
    }

    .lines {
        width: 100%;
        margin-top: -17px;

        @media(max-width: $md3+px) {
            display: none;
        }
    }
}