@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 368px auto;
    justify-content: space-between;
    gap: 80px;

    @media (max-width: $md3+px) {
        display: flex;
        flex-direction: column;
        gap: 60px;
    }

    .content {

        .title {
            color: #D1B06B;
            font-family: $sans;
            font-size: 35px;
            font-weight: 400;
            line-height: 150%;

            @media(max-width: $md6+px) {
                font-size: 28px;
            }
        }

        .text {
            margin: 30px 0 15px;

            @media (max-width: $md3+px) {
                max-width: 600px;
            }
        }

        .desc {
            color: #FFF;
            font-family: $sans;
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;

            @media (max-width: $md3+px) {
                max-width: 400px;
            }
        }

        .btn {
            display: none;
        }
    }

    .accountsWrap {
        display: flex;

        @media (max-width: $md3+px) {
            justify-content: center;
        }

        @media (max-width: $md5+px) {
            flex-direction: column;
            justify-content: unset;
            gap: 20px;
        }

        .buttons {
            width: 107px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            @media (max-width: $md5+px) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                width: 100%;
            }

            .button {
                width: 100%;
                height: 55px;
                border-radius: 10px 0 0 10px;
                color: #2A2B2B;
                font-family: $sans;
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
                background: #E3DCCD;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                cursor: pointer;
                transition: all .3s;

                @media (max-width: $md5+px) {
                    border-radius: 0;
                }

                @media (max-width: $md6+px) {
                    height: 45px;

                    &:nth-child(4), &:nth-child(5) {
                        transform: translateX(calc(50% + 4px));
                    }
                }


                &.active {
                    color: #000;
                    background: #D1B06B;
                }
            }
        }

        .accounts {
            width: 520px;
            border-radius: 0 20px 20px 20px;
            background: #343842;
            padding: 20px 30px 30px;

            @media (max-width: $md5+px) {
                border-radius: 20px;
                width: 100%;
            }

            @media (max-width: 600px) {
                padding: 10px;
            }

            @media (max-width: $md6+px) {
                width: calc(100% + 36px);
                transform: translateX(-18px);
                border-radius: 0;
            }

            .row {
                border-bottom: 1px solid #fff;
                display: grid;
                grid-template-columns: 53% 47%;
                height: 42px;
                align-items: center;

                font-family: $sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;

                .col {
                    padding: 0 10px;
                }

                img {
                    width: 20px;
                }
            }
        }
    }
}