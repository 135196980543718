@import '../../../styles/var';

.wrap {
    max-width: 2000px;
    width: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

.panel {
    position: relative;

    .image {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top right;

        @media(max-width: $md4+px) {
            position: relative;
            height: auto;
        }
    }

    .contentWrap {
        position: relative;
        z-index: 2;
        height: 430px;
        display: flex;
        align-items: center;
        width: 720px;
        padding-right: 150px;

        @media(max-width: $md3+px) {
            padding-right: 50px;
            height: 380px;
        }

        @media(max-width: $md4+px) {
            padding: 30px 0;
            height: auto;
            width: 100%;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: 3;
            background: rgba(55, 57, 63, 0.91);
            backdrop-filter: blur(9.5px);
            width: 100vw;
            height: 100%;
            top: 0;
            right: 0;

            @media(max-width: $md4+px) {
                width: calc(100vw + 10px);
                left: 50%;
                transform: translateX(calc(-50% - 5px));
            }
        }

        .content {
            position: relative;
            z-index: 4;
    
            .title {
    
            }
    
            .lines {
                width: 95px;
                margin: 20px 0 30px 0;
    
                @media(max-width: $md4+px) {
                    margin: 15px 0;
                }
                
                @media(max-width: $md6+px) {
                    width: 65px;
                }
            }
    
            .text {
    
            }
        }
    }
}

.cards {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    gap: 35px;

    @media(max-width: $md4+px) {
        margin-top: 40px;
        gap: 30px;
    }

    .card {
        width: 100%;
        max-width: 1010px;
        display: grid;
        grid-template-columns: auto 627px;
        justify-content: space-between;
        gap: 40px;
        margin: 0 auto;

        @media(max-width: $md3+px) {
            grid-template-columns: auto 550px;
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        &Title {}

        &Text {}
    }
}