@import '../../../styles/var';

.faq {
    position: relative;

    .title {
        text-align: center;
    }

    .tabs {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 1010px;
        margin: 75px auto 0;

        @media(max-width: $md4+px) {
            margin-top: 60px;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }

        .tab {
            background: #1F2732;

            &.active {
                .tabText {
                    display: block;
                }

                .tabStatus::before {
                    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
                }
            }

            &Header {
                cursor: pointer;
                padding: 20px;
                display: grid;
                grid-template-columns: 1fr 22px;
                gap: 40px;
                align-items: center;

                @media(max-width: $md6+px) {
                    grid-template-columns: 1fr 16px;
                    padding: 20px 15px;
                }

                .tabTitle {}

                .tabStatus {
                    position: relative;
                    width: 22px;
                    height: 22px;

                    @media(max-width: $md6+px) {
                        width: 16px;
                        height: 16px;
                    }

                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        background: $gold;
                        transition: all .1s;
                    }

                    &::before {
                        width: 2px;
                        height: 100%;
                    }

                    &::after {
                        width: 100%;
                        height: 2px;
                    }
                }
            }

            &Text {
                display: none;
                padding: 0 85px 20px 20px;

                @media(max-width: $md6+px) {
                    padding: 0 15px 20px 15px;
                }
            }
        }
    }
}