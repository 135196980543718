@import '../../../styles/var';

.banner {
    width: 100%;
    padding-top: 200px;
    display: flex;
    gap: 15px;

    @media(max-width: $md3+px) {
        padding-top: 125px;
    }

    @media(max-width: $md6+px) {
        padding-top: 100px;
    }

    .image {
        width: 479px;

        img {
            width: 100%;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 479px);
        gap: 20px;

        @media(max-width: $md3+px) {
            width: 100%;
            gap: 50px;
        }

        @media(max-width: $md6+px) {
            gap: 40px;
        }

        .head {
            padding-left: 20px;

            @media(max-width: $md3+px) {
                padding-left: 0;
            }

            .title {
                color: $gold;
            }

            .text {
                margin-top: 30px;
                max-width: 562px;

                @media(max-width: $md3+px) {
                    max-width: 772px;
                }

                @media(max-width: $md6+px) {
                    margin-top: 15px;
                }
            }
        }

        .swiperWrap {
            position: relative;
            width: 100%;
            background: #1F2732;
            padding: 40px 0 16px;

            @media(max-width: $md3+px) {
                padding: 30px 0 15px;
            }   

            .card {
                text-align: center;
                padding: 0 20px;

                @media(max-width: $md6+px) {
                    padding: 0 16px;
                }

                &Title {}

                &Text {
                    max-width: 517px;
                    margin: 25px auto 0;
                    line-height: 150%;
                    font-size: 15px;

                    @media(max-width: $md3+px) {
                        max-width: 612px;
                        margin-top: 15px;
                    }

                    @media(max-width: $md6+px) {
                        font-size: 14px;
                    }
                }
            }

            .nav {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 20px;
                margin-top: 20px;
                margin-right: 23px;

                @media(max-width: $md6+px) {
                    gap: 13px;
                    margin-right: 16px;
                }

                &Btn {
                    width: 40px;
                    height: 40px;
                    background-image: url('../../../assets/img/swiper_arrow.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    cursor: pointer;
                    transition: all .1s;

                    @media(max-width: $md6+px) {
                        width: 30px;
                        height: 30px;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }

                .right {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

:global(.faqBannerSwiper) {
    width: 100% !important;
}

:global(.faqBannerSwiperSlide) {
    height: auto !important;
}