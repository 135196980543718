@import '../../../styles/var';

.explore {
    position: relative;
    
    .title {
        text-align: center;
        
        @media(max-width: $md6+px) {
            br {
                display: none;
            }
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        margin-top: 75px;

        @media(max-width: $md4+px) {
            margin-top: 60px;
        }

        @media(max-width: $md5+px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 30px;
        }

        @media(max-width: $md6+px) {
            margin-top: 30px;
        }

        .card {
            text-align: center;
            gap: 50px;

            .num {
                
            }

            &Title {
                margin: 10px 0;
            }

            &Text {
                max-width: 221px;
                margin: 0 auto;
            }
        }
    }
}