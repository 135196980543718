@import '../../../styles/var';

.banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    height: 100vh;
    min-height: 740px;
    padding-top: 66px;

    @media(max-width: $md1+px) {
        min-height: 650px;
    }

    @media(max-width: $md3+px) {
        flex-direction: column;
        height: auto;
        min-height: auto;
        padding-top: 125px;
    }

    @media(max-width: $md4+px) {
        gap: 65px;
    }

    @media(max-width: $md6+px) {
        padding-top: 100px;
        gap: 50px;
    }

    .content {
        position: relative;

        @media(max-width: $md3+px) {
            text-align: center;
        }

        .lines {
            width: 95px;
            @media(max-width: $md3+px) {
                display: none;
            }
        }
        .title {
            margin: 7px 0 50px 0;

            @media(max-width: $md3+px) {
                margin-top: 0;
            }

            @media(max-width: $md6+px) {
                margin-bottom: 40px;
            }
        }
        .btn {

        }

        @media(max-width: $md3+px) {
            &::before, &::after {
                content: '';
                position: absolute;
                top: 22px;
                width: 200%;
                height: 44px;
                background-image: url('../../../assets/img/lines/lines_large.svg');
                background-position: center right;
                background-size: contain;
                background-repeat: no-repeat;

                @media(max-width: $md4+px) {
                    top: 10px;
                }

                @media(max-width: $md6+px) {
                    height: 25px;
                    top: 8px;
                }
            }
            &::before {
                left: -200%;
            }
            &::after {
                right: -200%;
                transform: rotate(180deg);
            }
        }
    }

    .image {
        position: relative;
        width: 612px;
        height: 560px;
        right: 40px;

        @media(max-width: $md1+px) {
            transform: scale(0.9);
            right: 0;
        }

        @media(max-width: $md3+px) {
            right: 70px;
        }

        @media(max-width: $md4+px) {
            display: none;
        }

        img {
            position: absolute;
        }

        .phone_01 {
            z-index: 3;
            top: 0;
            right: 220px;
        }
        
        .phone_02 {
            z-index: 2;
            bottom: 0;
            right: 55px;
        }

        .graph {
            z-index: 4;
            left: 0;
            bottom: 67px;
        }

        .coin_01 {
            z-index: 5;
            left: 121px;
            bottom: 197px;
        }

        .coin_02 {
            z-index: 1;
            top: 182px;
            left: 176px;
        }

        .coin_03 {
            z-index: 1;
            top: 165px;
            right: 0;
        }
    }

    .imageMob {
        max-width: 397px;
        width: 100%;
    }
}