@import '../../../styles/var';

.theseTools {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 525px);
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    @media(max-width: $md3+px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;
    }

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column-reverse;
    }

    @media(max-width: $md6+px) {
        gap: 50px;
    }

    .image {
        img {
            width: 100%;
            width: 434px;
            display: flex;
            margin: 0 auto;

            @media(max-width: $md3+px) {
                width: 100%;
            }

            @media(max-width: $md4+px) {
                width: 434px;
            }

            @media(max-width: $md6+px) {
                width: 247px;
            }
        }
    }
    
    .content {
        .text {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media(max-width: $md4+px) {
                gap: 15px;
            }
        }
    }
}