@import '../../../styles/var';

.underBanner {
    display: grid;
    grid-template-columns: repeat(2, 525px);
    justify-content: space-between;
    gap: 40px;

    @media(max-width: $md3+px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;
        margin-top: 160px;
    }

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 140px;
    }

    @media(max-width: $md6+px) {
        gap: 100px;
        margin-top: 100px;
    }

    .tabs {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .tab {
            position: relative;
            cursor: pointer;
            padding-left: 38px;

            @media(max-width: $md6+px) {
                padding-left: 23px;
            }

            &.active {
                .tabText {
                    display: block;
                }
            }

            &Title {}

            &Text {
                display: none;
                margin-top: 20px;
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 8px;
                background: $gold;
                border-radius: 2px;
            }
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}