@import "../../../styles/var";

.button {
    position: relative;
    display: inline-flex;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;

    padding: 15px 40px;
    border: 1px solid #D1B06B;
    background: #D1B06B;

    font-family: $sans;
    font-weight: 600;
    font-size: 17px;
    line-height: 120%;
    color: #000;
    text-transform: capitalize;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media (max-width: $md6+px) {
        font-size: 14px;
        padding: 14px 40px;
    }

    &:hover {
        background: transparent;
        color: #D1B06B;
    }

    span {
        position: relative;
        width: 100%;
        text-align: center;
    }
}