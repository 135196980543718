@import '../../../styles/var';

.wrap {
    max-width: 2000px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
    position: relative;

    background-image: url('../../../assets/img/HomePage/underbanner.avif');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;

    @media(max-width: $md3+px) {
        background-position: center;
        margin-top: 160px;
    }

    @media(max-width: $md6+px) {
        margin-top: 100px;
    }
}

.underBanner {
    position: relative;
    padding: 50px 0;
    min-height: 436px;
    display: flex;
    align-items: center;

    @media(max-width: $md3+px) {
        min-height: unset;
    }

    @media(max-width: $md6+px) {
        padding: 40px 0;
    }

    .text {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 525px;
        color: #161D27;

        @media(max-width: $md4+px) {
            gap: 15px;
        }
    }
}