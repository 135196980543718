@import '../../../styles/var';

.advanced {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 525px);
    justify-content: space-between;
    gap: 40px;

    @media(max-width: $md3+px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;
    }

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column;
    }

    @media(max-width: $md6+px) {
        gap: 40px;
    }

    .col {
        .options {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media(max-width: $md4+px) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 12px;
            }

            @media(max-width: $md5+px) {
                grid-template-columns: repeat(2, 1fr);

                .option:nth-child(5) {
                    transform: translateX(calc(50% + 6px));
                }
            }

            .option {
                width: 100%;
                position: relative;
                text-align: center;
                overflow: hidden;
                cursor: pointer;
                transition: all 0.3s;
                display: flex;
                align-items: center;
                justify-content: center;

                padding: 15px 40px;
                border: 1px solid #1F2732;
                background: #1F2732;

                font-family: $sans;
                font-weight: 600;
                font-size: 17px;
                line-height: 120%;
                color: #fff;
                text-transform: capitalize;
                text-align: center;

                @media(max-width: $md4+px) {
                    font-size: 16px;
                    padding: 12px 10px;
                }

                @media (max-width: $md6+px) {
                    font-size: 14px;
                    padding: 10px 5px;
                }

                &:hover {
                    border-color: #D1B06B;
                }

                &.active {
                    background: #D1B06B;
                    border-color: #D1B06B;
                    color: #000;
                }
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 30px;

            @media(max-width: $md4+px) {
                gap: 15px;
            }

            &Left {
                margin-top: 50px;
            }
        }

        .content {

        }

        .tabs {
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 10px;

            margin-top: 40px;

            .tab {
                background: #1F2732;

                &.active {
                    .tabText {
                        display: block;
                    }
                    .tabStatus::before {
                        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
                    }
                }
                
                &Header {
                    cursor: pointer;
                    padding: 20px;
                    display: grid;
                    grid-template-columns: 1fr 22px;
                    gap: 40px;
                    align-items: center;

                    @media(max-width: $md6+px) {
                        grid-template-columns: 1fr 16px;
                        padding: 20px 15px;
                    }

                    .tabTitle {

                    }

                    .tabStatus {
                        position: relative;
                        width: 22px;
                        height: 22px;

                        @media(max-width: $md6+px) {
                            width: 16px;
                            height: 16px;
                        }

                        &::before, &::after {
                            content: '';
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            background: $gold;
                            transition: all .1s;
                        }

                        &::before {
                            width: 2px;
                            height: 100%;
                        }

                        &::after {
                            width: 100%;
                            height: 2px;
                        }
                    }
                }

                &Text {
                    display: none;
                    padding: 0 85px 20px 20px;

                    @media(max-width: $md6+px) {
                        padding: 0 15px 20px 15px;
                    }
                }
            }

        }
    }
}