@import '../../../styles/var';

.various {
    position: relative;

    .block {
        margin-top: 110px;
        display: grid;
        grid-template-columns: repeat(2, 525px);
        gap: 60px;
        justify-content: space-between;

        @media(max-width: $md3+px) {
            grid-template-columns: 1fr 525px;
        }

        @media(max-width: 1100px) {
            grid-template-columns: 1fr 450px;
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 60px;
            justify-content: initial;
            margin-top: 60px;
        }

        @media(max-width: $md6+px) {
            gap: 40px;
            margin-top: 40px;
        }

        .content {
            width: 100%;
            .title {
                max-width: 450px;
            }

            .linesSmall {
                width: 95px;
                margin: 20px 0 30px 0;

                @media(max-width: $md4+px) {
                    margin: 15px 0;
                }

                @media(max-width: $md6+px) {
                    width: 65px;
                }
            }

            .text {
                display: flex;
                flex-direction: column;
                gap: 20px;

                @media(max-width: $md4+px) {
                    gap: 15px;
                }
            }
        }

        .col {
            display: flex;
            flex-direction: column;
            gap: 30px;
            .tab {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }
        }
    }
}