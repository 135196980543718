@import '../../../styles/var';

.various {
    position: relative;

    .block {
        display: grid;
        grid-template-columns: repeat(2, 525px);
        gap: 60px;
        justify-content: space-between;

        @media(max-width: $md3+px) {
            grid-template-columns: 1fr 525px;
        }

        @media(max-width: 1100px) {
            grid-template-columns: 1fr 450px;
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 60px;
            justify-content: initial;
        }

        @media(max-width: $md6+px) {
            gap: 40px;
        }

        .content {
            width: 100%;
            .title {
                max-width: 450px;
            }

            .linesSmall {
                width: 95px;
                margin: 20px 0 30px 0;

                @media(max-width: $md4+px) {
                    margin: 15px 0;
                }

                @media(max-width: $md6+px) {
                    width: 65px;
                }
            }

            .text {
                display: flex;
                flex-direction: column;
                gap: 20px;

                @media(max-width: $md4+px) {
                    gap: 15px;
                }
            }
        }

        .col {
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 10px;

            .tab {
                background: #1F2732;

                &.active {
                    .tabText {
                        display: block;
                    }
                    .tabStatus::before {
                        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
                    }
                }
                
                &Header {
                    cursor: pointer;
                    padding: 20px;
                    display: grid;
                    grid-template-columns: 1fr 22px;
                    gap: 40px;
                    align-items: center;

                    @media(max-width: $md6+px) {
                        grid-template-columns: 1fr 16px;
                        padding: 20px 15px;
                    }

                    .tabTitle {

                    }

                    .tabStatus {
                        position: relative;
                        width: 22px;
                        height: 22px;

                        @media(max-width: $md6+px) {
                            width: 16px;
                            height: 16px;
                        }

                        &::before, &::after {
                            content: '';
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            background: $gold;
                            transition: all .1s;
                        }

                        &::before {
                            width: 2px;
                            height: 100%;
                        }

                        &::after {
                            width: 100%;
                            height: 2px;
                        }
                    }
                }

                &Text {
                    display: none;
                    padding: 0 85px 20px 20px;

                    @media(max-width: $md6+px) {
                        padding: 0 15px 20px 15px;
                    }
                }
            }
        }
    }
}